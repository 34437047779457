import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["selectAll","collectionCheckbox","collectionForm","table"]
  }

  connect() {
    this.enable_collection_actions()
  }

  tableTargetConnected() {
    if(this.collectionFormTargets.length > 0)
    { //aggiungo i checkboxes manualmente, uno per riga
      $(this.tableTarget).find('tbody > tr').each(function(index, tr) {
        var checkbox = $('<input>').attr({
          type: 'checkbox',
          id: $(tr).attr('data-resource-id'),
          'data-resources--checkboxes-target': 'collectionCheckbox',
          'data-action': 'resources--checkboxes#collection_select_single'
        })
        $(tr).prepend($('<td>').append(checkbox))
      });

      //selettore all
      var checkbox = $('<input>').attr({
        type: 'checkbox',
        'data-resources--checkboxes-target': 'selectAll',
        'data-action': 'resources--checkboxes#collection_select_all'
      })
      $(this.tableTarget).find('thead > tr').prepend($('<th>').append(checkbox))
    }
  }

  collection_select_all() {
    $(this.collectionCheckboxTargets).prop('checked', $(this.selectAllTarget).prop('checked'));
    this.enable_collection_actions();
  }
  collection_select_single() {
    this.enable_collection_actions();
  }

  submit_collection_form(event) {
    event.preventDefault();

    var form = $(this.collectionFormTarget).closest('form')

    this.collectionCheckboxTargets.
      filter ( (elem) => { return $(elem).prop('checked') }).
      forEach( (elem) => {
        $('<input>').attr({
          type: 'hidden',
          name: 'collection_ids[]',
          value: $(elem).attr('id')
        }).appendTo(form); })

    Rails.fire(form[0], 'submit')
  }

  enable_collection_actions() {
    if(!this.any_checkbox_selected())   $(this.collectionFormTargets).addClass('disabled')
    else                                $(this.collectionFormTargets).removeClass('disabled')
  }
  any_checkbox_selected() {
    return(this.collectionCheckboxTargets.
      filter ( (elem) => { return $(elem).prop('checked') }).length > 0)
  }
}