import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["table"]
  }

  // the data-url presence activates table sorting
  tableTargetConnected() {
    var sortable = $(this.tableTarget).data('sort-url')

    if (sortable != undefined)
    { this.add_anchors();
      this.enable_table_sorting();
    }
  }

  add_anchors() {
    $(this.tableTarget).find('tbody > tr').each(function(index, tr) {
      var anchor = $('<span>').attr({
        class: 'fa fa-arrows-alt'
      })
      $(tr).prepend($('<td>').append(anchor))
    });
    //th
    $(this.tableTarget).find('thead > tr').prepend($('<th>'))
  }
  
  enable_table_sorting() {
    // top pos
    var top_position = $('tbody > tr:first-child', this.tableTarget).data('resource-position') - 1
    // tablednd activation
    $(this.tableTarget).tableDnD({ 
      onDragClass: "dragging-table-row",
      onDrop: function(table, row) {
        var positions = {};
        $.each($('tbody > tr', table), function(position, obj){
          var id = $(obj).data('resource-id');
          positions['positions['+id+']'] = position + top_position;
        });
        $.ajax({
          type: 'POST',
          dataType: 'script',
          url: $(table).data('sort-url'),
          data: positions,
          success: function(data){
          }
        });
      }
    });
  }
}
