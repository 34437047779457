import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["formContainer","form","filterActivator", "searchInput", "quickSearch","filterReset"]
  }

  connect() {
    // se un campo del search è valorizzato apro il form di ricerca
    if(!this.test_inputs_are_empty()) $(this.formContainerTarget).show();
    this.verify_reset_button()
  }

  submit(event) {
    this.formTarget.requestSubmit()
    this.verify_reset_button() 
  }

  collapse_form(event) {
    event.preventDefault();
    $(this.formContainerTarget).slideToggle()
  }

  submitQuickSearch(event) {
    var quick_value = this.quickSearchTarget.value;
    this.searchInputTargets.
      filter ( (elem) => { return $(elem).hasClass('quick-search') }).
      forEach( (elem) => { elem.value = quick_value })
    this.formTarget.requestSubmit()
    this.verify_reset_button()
  }

  search_reset(event) {
    event.preventDefault();
    $(this.filterResetTarget).addClass('disabled');
    this.searchInputTargets.forEach( (elem) => { elem.value = null} )
    this.quickSearchTarget.value = null
    this.formTarget.requestSubmit()
    $(this.formContainerTarget).hide()
  }

  verify_reset_button() {
    if(this.test_inputs_are_empty())  $(this.filterResetTarget).addClass('disabled')
    else                              $(this.filterResetTarget).removeClass('disabled')
  }

  test_inputs_are_empty() {
    var sources = this.searchInputTargets.concat(this.quickSearchTarget)
    var empty = true;
    for (let element of sources) {
      if (element.value.length != 0) {
        empty = false;
        break;
      }
    }
    return(empty)
  }
}